import './loadingSpinner.css';

const LoadingSpinner = () => (
    <div className="loader"></div>
);
  
const CenteredSpinner = () => (
    <div className='centered-container'>
        <LoadingSpinner />
    </div>
);

export default CenteredSpinner;