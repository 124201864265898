import React from "react";
import { SavedArticle } from "../../redux/model/SavedArticle";
import SavedArticleCard from "../Cards/SavedArticleCard";

interface Props {
    articles: SavedArticle[];
    showModal: () => void;
}

const SavedArticlesTable: React.FC<Props> = ({ articles, showModal }) => {
    return (
        <div className="rounded-2xl border px-5 pt-6 pb-2.5 border-bodylight1 bg-bodylight shadow-default dark:border-black dark:bg-boxdark-2 sm:px-7.5 xl:pb-1">
            <div className="max-w-full overflow-x-auto">
                <p className="text-black dark:text-white text-title-md font-semibold">
                    Saved Articles
                </p>
                <div className="bg-bodylight1 dark:bg-strokedark mt-4 w-full" style={{height: '1px'}} />
                {
                    articles.length > 0
                    ?
                    <div className="grid grid-cols-3 gap-4 mb-8 mt-6 auto-rows-auto">
                        {
                            articles.map((article, index) => (
                                <SavedArticleCard key={index} article={article} />
                            ))
                        }
                    </div>
                    :
                    <div className="flex flex-col items-center justify-center">
                        <p className="text-black dark:text-white text-title-sm font-light mt-8">
                            You don't have any saved articles yet. Start with <button className="underline text-black dark:text-white" onClick={() => window.open('https://medium.com/@max_10825/how-i-never-forget-anything-%EF%B8%8F-b078c8c50b8a', '_blank')}>this one</button>!
                        </p>
                        <button
                            className="bg-primary text-white rounded-lg transition py-2 px-4 mt-6 mb-8"
                            onClick={() => showModal()}
                        >
                            How do I save an article?
                        </button>
                    </div>
                }
            </div>
        </div>
    );
};

export default SavedArticlesTable;
