import React, { useState } from "react";

import { ServerResponse } from "../../redux/model/Request";

interface Props {
    requests: ServerResponse[];
    showModal: () => void;
}

const RequestTable:React.FC<Props> = ({ requests, showModal }) => {

    const [selectedRow, setSelectedRow] = useState<number | null>(null);

    // function to format title if length > 35 characters
    function formatRequestTitle(title: string) {
        if (title.length > 48) {
            return title.slice(0, 48) + "...";
        }
        return title;
    }

    function handleRowClick(index: number) {
        setSelectedRow(selectedRow === index ? null : index);
      }

      return (
        <div className="rounded-2xl border px-5 pt-6 pb-2.5 shadow-default border-bodylight bg-bodylight dark:bg-boxdark-2 dark:border-black sm:px-7.5 xl:pb-1">
            <div className="max-w-full overflow-x-auto">
                <table className="w-full table-auto">
                    <thead className="text-black dark:text-white text-title-md font-semibold">
                        <tr>
                            <td>Recent Searches</td>
                        </tr>
                        <tr>
                            <td>
                                <div className="w-full mt-4 bg-bodydark dark:bg-strokedark rounded-full" style={{height: "0.5px"}} />
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            requests.length > 0
                            ?
                            requests.map((request, index) => (
                                <React.Fragment key={index}>
                                    <tr className={`hover:bg-bodylight dark:hover:bg-meta-4 ${selectedRow === index ? 'bg-bodylight dark:bg-meta-4' : ''} cursor-pointer`} onClick={() => handleRowClick(index)}>
                                        <td className="py-3 px-4">
                                            <h5 className="font-medium text-sm text-boxdark dark:text-bodylight1">
                                                {formatRequestTitle(requests[index][0].query)}
                                            </h5>
                                        </td>
                                    </tr>
                                    {selectedRow === index && (
                                        <tr className={`hover:bg-bodylight dark:hover:bg-meta-4 ${selectedRow === index ? 'bg-bodylight dark:bg-meta-4' : ''} cursor-pointer`}>
                                            <td className="px-4">
                                                <p className="text-xs text-boxdark dark:text-bodylight1 animate-slideIn mb-4">
                                                    {requests[index][0].response}
                                                </p>
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td>
                                            <div className="w-full bg-bodylight1 dark:bg-strokedark rounded-full" style={{height: "1px"}} />
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))
                            :
                            <tr className="flex flex-col items-center justify-center">
                                <td>
                                    <p className="text-black dark:text-white text-title-sm font-light mt-8">
                                        You don't have any recent searches yet. Make one at <button className="underline text-black dark:text-white" onClick={() => window.open('https://medium.com/@max_10825/how-i-never-forget-anything-%EF%B8%8F-b078c8c50b8a', '_blank')}>this article</button>!
                                    </p>
                                </td>
                                <td>
                                    <button
                                        className="bg-primary text-white rounded-lg transition py-2 px-4 mt-6 mb-8"
                                        onClick={() => showModal()}
                                    >
                                        How do I make a search?
                                    </button>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
  };
  
  export default RequestTable;
  