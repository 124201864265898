import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import { connect } from 'react-redux';
import { User } from './redux/model/User';
import { RootState } from './redux/store';
import { getUserAction, logoutUserAction, getDashboardMetricsAction, getSavedArticlesAction } from './redux/users/UserActions';
import { DashboardMetrics } from './redux/model/DashboardMetrics';

import './App.css';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from '@firebase/auth';

import axios from 'axios';

import Landing from './pages/Landing';
import Login from './pages/auth/Login';
import Signup from './pages/auth/Signup';
import Home from './pages/dashboard/Home';
import Settings from './pages/dashboard/Settings'

import LoadingSpinner from './components/Spinner/LoadingSpinner';
import Demo from './pages/Demo';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import { SavedArticle } from './redux/model/SavedArticle';

const firebaseConfig = {
  apiKey: "AIzaSyAEUVdwBf_1Y2PZX5Lvpvr_zwRdGS1VSxc",
  authDomain: "illiad-ai-f0ed9.firebaseapp.com",
  projectId: "illiad-ai-f0ed9",
  storageBucket: "illiad-ai-f0ed9.appspot.com",
  messagingSenderId: "831722210874",
  appId: "1:831722210874:web:e55d90eaccd77907fb1703",
  measurementId: "G-PWP60DFRK9"
};

interface Props {
    user: User | null;
    loadingUser: boolean;
    dashboardMetrics: DashboardMetrics;
    loadingDashboardMetrics: boolean;
    savedArticles: SavedArticle[];
    loadingSavedArticles: boolean;
    subscriptions: any;
    request_limit: number;
    getUserAction: (userId: string) => void;
    logoutUserAction: () => void;
    getDashboardMetricsAction: (userId: string, pageNumber: number) => void;
    getSavedArticlesAction: (userId: string) => void;
}

const App:React.FC<Props> = ({ user, subscriptions, request_limit, loadingUser, dashboardMetrics, savedArticles, loadingSavedArticles, loadingDashboardMetrics, getUserAction, logoutUserAction, getDashboardMetricsAction, getSavedArticlesAction }) => {

    // console.log(user);
    // console.log(dashboardMetrics);
    // console.log(subscriptions);

    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const auth = getAuth();

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const api_key = process.env.REACT_APP_API_KEY;
        axios.defaults.headers.common['Authorization'] = `Bearer ${api_key}`;

        if (isSignInWithEmailLink(auth, window.location.href)) {
            // Additional state parameters can also be passed via URL.
            // This can be used to continue the user's intended action before triggering
            // the sign-in operation.
            // Get the email if available. This should be available if the user completes
            // the flow on the same device where they started it.
            let email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
              // User opened the link on a different device. To prevent session fixation
              // attacks, ask the user to provide the associated email again. For example:
              email = window.prompt('Please provide your email for confirmation');
            } else {
                signInWithEmailLink(auth, email, window.location.href)
                  .then((result) => {
                    // Clear email from storage.
                    window.localStorage.removeItem('emailForSignIn');
                    // You can access the new user via result.user
                    // You can check if the user is new or existing:
                    // result.additionalUserInfo.isNewUser

                    window.location.href = '/';
                  })
                  .catch((error) => {
                    // Some error occurred, you can inspect the code: error.code
                    // Common errors could be invalid email and invalid or expired OTPs.
                  });
            }
        }

        auth.onAuthStateChanged((authUser) => {
            setLoading(false);
            if (authUser) {
                authUser.getIdTokenResult().then((idTokenResult) => {
                    if (idTokenResult.claims.user_id) {
                        const uid = idTokenResult.claims.user_id.toString();
                        // console.log(uid);
                        getUserAction(uid);
                        getDashboardMetricsAction(uid, 1);
                        getSavedArticlesAction(uid);
                    } else {
                        logoutUserAction();
                        window.postMessage({ type: "AUTH_STATE_CHANGED", user: null }, "*");
                    }
                });
            } else {
                logoutUserAction();
                window.postMessage({ type: "AUTH_STATE_CHANGED", user: null }, "*");
            }
        });
    }, [auth, getUserAction, logoutUserAction, getDashboardMetricsAction, getSavedArticlesAction]);

    useEffect(() => {
        //  only send active stripe subscriptions to the frontend
        let activeSubscriptions = subscriptions;
        if (subscriptions != null && subscriptions.data != null) {
            activeSubscriptions = subscriptions.data.filter((subscription: any) => {
                return subscription.status === 'active' || subscription.status === 'trialing';
            });
        }

        let userPayload = {
            uid: user != null ? user.firebase_id : '',
            email: user != null ? user.email : '',
            subscriptions: activeSubscriptions,
        }
        window.postMessage({ type: "AUTH_STATE_CHANGED", user: userPayload }, "*");
    }, [user, subscriptions]);

    return (
        <>
            {
                loading === true || loadingUser === true
                ?
                <div className='w-full h-full items-center bg-white dark:bg-black'>
                    <LoadingSpinner />
                </div>
                :
                user != null
                ?
                <Routes>
                    <Route path="/" element={ <Home user={user} subscriptions={subscriptions} request_limit={request_limit} metrics={dashboardMetrics} savedArticles={savedArticles} loadingDashboardMetrics={loadingDashboardMetrics} loadingSavedArticles={loadingSavedArticles} /> } />
                    <Route path="/login" element={ <Login /> } />
                    <Route path="/signup" element={ <Signup /> } />
                    <Route path="/privacy-policy" element={ <PrivacyPolicy /> } />
                    <Route path="terms-of-service" element={ <TermsOfService /> } />
                </Routes>
                :
                <Routes>
                    <Route path="/" element={ <Landing /> } />
                    {/* <Route path="/" element={ <Demo /> } /> */}
                    <Route path="/login" element={ <Login /> } />
                    <Route path="/signup" element={ <Signup /> } />
                    <Route path="/privacy-policy" element={ <PrivacyPolicy /> } />
                    <Route path="terms-of-service" element={ <TermsOfService /> } />
                </Routes>
            }
        </>
    );
}

const mapStateToProps = (state: RootState) => ({
    user: state.user.user,
    subscriptions: state.user.subscriptions,
    request_limit: state.user.request_limit,
    loadingUser: state.user.loadingUser,
    dashboardMetrics: state.user.dashboardMetrics,
    loadingDashboardMetrics: state.user.loadingDashboardMetrics,
    savedArticles: state.user.savedArticles,
    loadingSavedArticles: state.user.loadingSavedArticles
});

const mapDispatchToProps = {
    getUserAction,
    logoutUserAction,
    getDashboardMetricsAction,
    getSavedArticlesAction
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(App);
export default connectedComponent;
