import { useNavigate } from "react-router-dom";

import { getAuth } from "@firebase/auth";

import { User } from "../../redux/model/User";
import { useRef, useState } from "react";

import enableGif from '../../img/enable.gif';
import enable from '../../img/enable.png';
import exampleChat from '../../img/example-chat.gif';
import popup from '../../img/popup.gif';

interface Props {
    user: User;
    dismiss: () => void;
}

const HowToMakeRequestModal:React.FC<Props> = ({ user, dismiss }) => {

    const navigate = useNavigate();
    const auth = getAuth();
    const ref = useRef(null);

    const [currentPage, setCurrentPage] = useState<number>(1);

    function openLink(url: string) {
        window.location.href = url;
    }

    function openLinkNewTab(url: string) {
        window.open(url, '_blank');
    }

    return(
        <div className="fixed inset-0 bg-black bg-opacity-5 px-8 backdrop-filter backdrop-blur-sm flex justify-center items-center z-9999">
            <div className="bg-white dark:bg-black px-12 py-8 rounded-2xl shadow-lg relative w-full md:w-3/4 lg:w-1/2 xl:w-1/3">
                <div className='text-black dark:text-white text-title-lg font-semibold'>
                    {
                        currentPage === 1
                        ?
                        <div>
                            <div className='flex justify-between items-start'>
                                <p className='pb-2'>
                                    How to Make a Request
                                </p>

                                <button 
                                    className='text-base underline'
                                    onClick={() => {
                                        dismiss();
                                    }}
                                >
                                    Dismiss
                                </button>
                            </div>

                            <p className="text-sm font-normal mt-4">
                                Follow this quick onboarding process to finish setting up your account.
                            </p>
                            <p className="text-sm font-normal mt-4">
                                To begin, head to <button className="underline text-black dark:text-white" onClick={() => openLink('https://chromewebstore.google.com/detail/illiad/jiggadnmcmbdpofgkablihabdghjdjhm')}>this link</button> to download the Illiad Chrome Extension. When you're done, the back button to come back to this page and click the button below.
                            </p>
                            <p className="text-sm font-normal mt-4">
                                You should see a lightning bolt to the right of this modal. If you don't see it, try  <button className="underline text-black dark:text-white" onClick={() => window.location.reload()}>refreshing the page</button>
                            </p>
                            
                            <div className="w-full">
                                <img 
                                    src={enable} 
                                    alt="" 
                                    className=" w-1/6 rounded-full mt-6 border border-bodylight dark:border-body shadow-md" style={{marginLeft: "42%"}} />
                            </div>
                        
                            <button 
                                className='bg-custom-gradient text-white font-bold text-title-sm py-2 px-4 rounded-lg mt-10 w-full shadow-md'
                                onClick={() => {
                                    setCurrentPage(2);
                                }}
                            >
                                I see the lightning bolt – let's go!
                            </button>
                        </div>
                        :
                        currentPage === 2
                        ?
                        <div>
                            <div className='flex justify-between items-start'>
                                <p className='pb-2'>
                                    Ask AI
                                </p>

                                <button 
                                    className='text-base underline'
                                    onClick={() => {
                                        setCurrentPage(1);
                                    }}
                                >
                                    Go back
                                </button>
                            </div>

                            <p className="text-sm font-normal mt-4">
                                Let's go through a quick example of how to use the "Ask AI" feature of Illiad.
                            </p>
                            <p className="text-sm font-normal mt-4">
                                First, enable the extension by clicking the lightning bolt to the right of this modal. The lightning bolt should turn from grey to yellow, indicating it is enabled.
                            </p>
                            <div className="w-full">
                                <img 
                                    src={enableGif} 
                                    alt="" 
                                    className=" w-1/6 rounded-lg mt-6" style={{marginLeft: "42%"}} />
                            </div>

                            <button 
                                className='bg-custom-gradient text-white font-bold text-title-sm py-2 px-4 rounded-lg mt-10 w-full shadow-md'
                                onClick={() => {
                                    setCurrentPage(3);
                                }}
                            >        
                                The extension is enabled
                            </button>
                        </div>
                        :
                        currentPage === 3
                        ?
                        <div>
                            <div className='flex justify-between items-start'>
                                <p className='pb-2'>
                                    Ask AI
                                </p>

                                <button 
                                    className='text-base underline'
                                    onClick={() => {
                                        setCurrentPage(2);
                                    }}
                                >
                                    Go back
                                </button>
                            </div>

                            <p className="text-sm font-normal mt-4">
                                Highlight an unfamiliar term in the block of text below:
                            </p>
                            <p className="text-sm font-normal mt-4 p-6 rounded-lg bg-bodylight1 dark:bg-graydark">
                                Large Language Models (LLMs) like OpenAI's GPT-3 represent a significant leap in artificial intelligence, particularly in natural language processing (NLP). At their core, LLMs are trained on vast datasets comprising diverse text sources, enabling them to generate, interpret, and respond to human language with unprecedented accuracy and versatility. 
                            </p>
                            <p className="text-sm font-normal mt-4">
                                When you highlight the term, "Ask AI" should popup. Click it to ask a question about the term you highlighted.
                            </p>
                            <p className="text-sm font-normal mt-4">
                                Congratulations! You've just used the "Ask AI" feature of Illiad for the first time. You can use this feature on any webpage.
                            </p>

                            <button 
                                className='bg-custom-gradient text-white font-bold text-title-sm py-2 px-4 rounded-lg mt-10 w-full shadow-md'
                                onClick={() => {
                                    dismiss();
                                }}
                            >        
                                Got it – let's get started!
                            </button>
                        </div>
                        :
                        null
                    }
                </div>
            </div>
        </div>
    );
}

export default HowToMakeRequestModal;