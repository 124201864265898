import axios from 'axios';
import { User } from '../redux/model/User';

// const API_BASE_URL = 'http://localhost:8080/api/v1';
const API_BASE_URL = 'https://main-lqjfui27za-ue.a.run.app/api/v1';

export async function createStripeCustomer(email: string, organization: string, name: string) {
    const response = await axios.post(API_BASE_URL + '/users/create-stripe-customer', {
        email: email,
        organization: organization,
        name: name
    });

    return response.data;
}

export async function createUser(user: User) {
    const response = await axios.post(API_BASE_URL + '/users/create-user', {
        user: user
    });

    return response.data;
}

export async function getUser(userId: string) {
    const response = await axios.post(API_BASE_URL + '/users/get-user', {
        firebase_id: userId
    });

    return response.data;
}

export async function getDashboardMetrics(userId: string, pageNumber: number) {
    const response = await axios.post(API_BASE_URL + '/users/get-user-dashboard', {
        firebase_id: userId,
        page_number: pageNumber
    });

    return response.data;
}

export async function getSavedArticles(userId: string) {
    const response = await axios.post(API_BASE_URL + '/users/saved-articles', {
        firebase_id: userId,
        collection_name: "general"
    });

    return response.data;
}

// Function to create a new checkout session with stripe 
export async function createCheckoutSession(product_id: string, customer_id: string): Promise<any> {
    const response = await axios.post(
        API_BASE_URL + '/users/create-checkout-session', 
        {
            product_id: product_id,
            customer_id: customer_id
        }
    );

    console.log(response);

    // If the checkout session was successfully created and we have a URL
    if (response.data.url) {
        // Open a new tab with the checkout URL
        // window.open(response.data.url, '_blank');
        window.location.href = response.data.url;
    }
    
    return response.data;
}

export async function createFreeTrial(customer_id: string): Promise<any> {
    const response = await axios.post(
        API_BASE_URL + '/users/create-free-trial', 
        {
            customer_id: customer_id
        }
    );

    return response.data;
}

export async function createBillingPortalSession(customer_id: string): Promise<any> {
    const response = await axios.post(
        API_BASE_URL + '/users/create-billing-portal-session', 
        {
            customer_id: customer_id
        }
    );

    console.log(response);

    // If the checkout session was successfully created and we have a URL
    if (response.data.url) {
        window.open(response.data.url, '_blank');
    }
    
    return response.data;
}

export async function cancelSubscription(subscription_id: string) {
    const response = await axios.post(API_BASE_URL + '/users/cancel-subscription', {
        subscription_id: subscription_id
    });

    return response.data;
}

export async function askDocuments(userId: string, query: string) {
    const response = await axios.post(API_BASE_URL + '/users/ask-documents', {
        firebase_id: userId,
        query: query
    });

    return response.data;
}