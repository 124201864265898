import React, { useEffect, useState } from 'react';
import { askDocuments } from '../../functions/userFunctions';
import { getAuth } from 'firebase/auth';

interface Message {
    sender: 'user' | 'ai' | 'example';
    text: string;
}

const ChatInterface: React.FC = () => {

    const auth = getAuth();

    const [messages, setMessages] = useState<Message[]>([
        { sender: 'example', text: 'What are the main themes in my saved articles?' },
        { sender: 'example', text: 'Where are some of the places I am saving content from?' }
    ]);
    const [currentMessage, setCurrentMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [loadingText, setLoadingText] = useState(".");

    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (isLoading) {
            interval = setInterval(() => {
                setLoadingText(prev => prev.length < 3 ? prev + "." : ".");
            }, 500);
        }
        return () => clearInterval(interval);
    }, [isLoading]);

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
        }
    };

    const handleSendMessage = (message?: string) => {
        const textToSend = message || currentMessage.trim();
        if (textToSend === "") return;

        setMessages([...messages, { sender: 'user', text: textToSend }]);
        setIsLoading(true);
        setCurrentMessage("");
        setMessages(prev => prev.filter(message => message.sender !== 'example'));

        const uid = auth.currentUser?.uid.toString() || "";
        if (uid === "") return;

        askDocuments(uid, textToSend).then(response => {
            setMessages(prev => [...prev, { sender: 'ai', text: response.response }]);
            setIsLoading(false);
        }).catch(error => {
            console.log(error);
            setIsLoading(false);
        });
    };

    const handleExampleClick = (exampleText: string) => {
        handleSendMessage(exampleText);
        setMessages(prev => prev.filter(message => message.sender !== 'example'));
    };
    return (
        <div className={`rounded-2xl border px-5 pt-6 pb-2.5 mb-8 border-bodylight1 bg-bodylight shadow-default dark:border-black dark:bg-boxdark-2 sm:px-7.5 xl:pb-1`}>
            <p className="text-black dark:text-white text-title-md font-semibold">
                Chat with your Knowledgebase
            </p>
            <div className="bg-bodylight1 dark:bg-strokedark mt-4 w-full" style={{height: '1px'}} />
            
            <div className={`flex-grow overflow-y-auto mt-4 ${isExpanded === true ? 'min-h-100' : 'min-h-3'}`}>
                {
                    messages.map((message, index) => (
                    <div key={index} className={`flex mt-2 ${message.sender === 'ai' ? 'justify-start' : 'justify-end'}`}>
                            <p
                                className={`p-3 rounded-lg ${message.sender === 'ai' ? 'bg-meta-9 dark:bg-graydark text-black dark:text-white' : message.sender === 'user' ? 'bg-meta-5 text-white' : 'bg-bodydark dark:bg-meta-4 text-white'}`}
                                onClick={() => message.sender === 'example' && handleExampleClick(message.text)}
                                style={{ cursor: message.sender === 'example' ? 'pointer' : 'default' }}
                            >
                                {message.text}
                            </p>
                        </div>
                    ))
                }
            </div>

            {
                isLoading && (
                    <div className="flex justify-start">
                        <p className="p-3 rounded-lg bg-meta-9 dark:bg-graydark text-white mt-2">{loadingText}</p>
                    </div>
                )
            }

            <div className="relative flex mt-4 mb-2">
                <input
                    type="text"
                    value={currentMessage}
                    onKeyPress={handleKeyPress}
                    onChange={(e) => setCurrentMessage(e.target.value)}
                    placeholder="Ask any question about your saved content..."
                    className="flex-grow p-3 rounded-xl border-2 text-black dark:text-white border-bodylight1 w-full dark:border-boxdark dark:bg-boxdark-2"
                />
                <button
                    onClick={() => handleSendMessage()}
                    className="absolute right-4 bg-custom-gradient text-white px-3 py-2 my-2 mx-2 rounded-lg text-sm"
                    style={{ marginRight: '-0.5rem' }} // Adjust as needed
                >
                    Send
                </button>
            </div>

        </div>
    );
};

export default ChatInterface;