import { useNavigate } from "react-router-dom";

import { signOut, getAuth } from "@firebase/auth";

import { User } from "../../redux/model/User";
import { logoutUserAction } from "../../redux/users/UserActions";
import { createBillingPortalSession, createCheckoutSession, createFreeTrial } from "../../functions/userFunctions";


interface Props {
    user: User;
}

const TrialExpiredModal:React.FC<Props> = ({ user }) => {

    const navigate = useNavigate();
    const auth = getAuth();

    function manageStripeSubscription() {
        const customer_id = user?.stripe_customer_id;
        if (user !== null && customer_id !== null && customer_id !== undefined) {
            createBillingPortalSession(customer_id);
        }
        // window.open('https://billing.stripe.com/p/login/5kA3eh49712X5vadQQ', '_blank');
    }

    return(
        <div className="fixed inset-0 bg-black bg-opacity-50 px-8 backdrop-filter backdrop-blur-sm flex justify-center items-center z-999999">
            <div className="bg-white dark:bg-black px-12 py-8 rounded-2xl shadow-lg relative w-full md:w-3/4 lg:w-1/2 xl:w-1/3">
                <div className='text-black dark:text-white text-title-lg font-semibold'>
                    <div className='flex justify-between items-start'>
                        <p className='pb-2'>
                            Your 14 Day Trial Has Expired
                        </p>

                        <button 
                            className='text-sm font-normal underline pl-2'
                            onClick={() => {
                                logoutUserAction();
                                window.postMessage({ type: "AUTH_STATE_CHANGED", user: null }, "*");
                                signOut(auth);
                                navigate('/');
                            }}
                        >
                            Logout
                        </button>
                    </div>
                    
                    <p className="text-sm font-normal pt-2 pb-6">
                        Subscribe today to continue enjoying the benefits of Illiad!
                    </p>
                
                    <button 
                        className='bg-custom-gradient text-white font-bold text-title-sm py-2 px-4 rounded-lg mt-2 w-full shadow-md'
                        onClick={() => {
                            manageStripeSubscription();
                        }}
                    >
                        Enter payment details and re-activate plan!
                    </button>
                </div>
            </div>
        </div>
    );
}

export default TrialExpiredModal;