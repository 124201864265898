import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

import { User } from "../../redux/model/User";
import { createStripeCustomer, createUser } from "../../functions/userFunctions";
import e from "express";

const Signup = () => {
    
    const auth = getAuth();
    const navigate = useNavigate();

    const [user, setUser] = useState<User>({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        organization_name: '',
        stripe_customer_id: '',
        firebase_id: ''
    });
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');

    const [activePage, setActivePage] = useState<'user' | 'teammates' | 'payment'>('user');
    const [validationErrors, setValidationErrors] = useState({
        first_name: '',
        last_name: '',
        email: '',
        organization_name: '',
        phone_number: '',
        password: '',
        confirmPassword: ''
    });
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {

    }, [user]);

    const isValidEmail = (email: string) => /\S+@\S+\.\S+/.test(email);
    
    const validateForm = () => {
        let errors = {
            first_name: '',
            last_name: '',
            email: '',
            organization_name: '',
            phone_number: '',
            password: '',
            confirmPassword: ''
        };
        let formIsValid = true;
    
        if (!user.first_name.trim()) {
            errors.first_name = 'First name is required';
            formIsValid = false;
        }
        if (!user.last_name.trim()) {
            errors.last_name = 'Last name is required';
            formIsValid = false;
        }
        if (!password.trim()) {
            errors.password = 'Password is required';
            formIsValid = false;
        }
        if (!confirmPassword.trim()) {
            errors.confirmPassword = 'Confirm password is required';
            formIsValid = false;
        }
        if (password !== confirmPassword) {
            errors.password = 'Passwords do not match'
            errors.confirmPassword = 'Passwords do not match';
            formIsValid = false;
        }
        if (!user.email.trim()) {
            errors.email = 'Email is required';
            formIsValid = false;
        } else if (!isValidEmail(user.email)) {
            errors.email = 'Email is invalid';
            formIsValid = false;
        }
        
        setValidationErrors(errors);
        return formIsValid;
    };

    function primaryButtonClicked() {
        setLoading(true);
        if (validateForm()) {
            createUserWithEmailAndPassword(auth, user.email, password).then((userCredential) => {
                // Signed in 
                const loggedInUser = userCredential.user;
                console.log(loggedInUser);
                setUser({
                    ...user,
                    firebase_id: loggedInUser != null ? loggedInUser.uid : ''
                })
                // create stripe user
                createStripeCustomer(user.email, user.organization_name, user.first_name + " " + user.last_name).then((response) => {
                    console.log(response);
                    const customer_id = response.customer_id;
                    // create user in database
                    createUser({
                        ...user,
                        stripe_customer_id: customer_id,
                        firebase_id: loggedInUser.uid
                    }).then((response) => {
                        // console.log(response);
                        navigate('/');
                        window.location.reload();
                    });
                });
            }).catch((error) => {
                console.log(error);
                setValidationErrors({
                    ...validationErrors,
                    email: 'A user with this email already exists.'
                });
            });
        }
        setLoading(false);
    }
    
    function secondaryButtonClicked() {
        navigate('/');
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUser({ ...user, [event.target.name]: event.target.value });
    };

    useEffect(() => {

    }, [validationErrors]);

    useEffect(() => {
        // if user is logged in, redirect to dashboard
        const loggedInUser = auth.currentUser;
        if (loggedInUser != null) {
            navigate('/');
        }
    }, [auth, navigate]);

    return (
        <div>
            <div className="relative h-screen w-full bg-landingBackgroundGradient flex items-top justify-center" style={{ backgroundImage: 'linear-gradient(30deg, #5B1DF5, #ffaf7b)' }}>
                <div className="bg-white border border-stroke rounded-xl w-4/5 lg:w-1/2 shadow-md" style={{height: "70vh", marginTop: "10vh"}}>
                    <div className="py-4 px-6.5 flex justify-between shadow-sm">
                        <h3 className="text-title-xsm sm:text-title-sm font-semibold mt-2 text-black dark:text-white">
                            Sign up for Illiad AI
                        </h3>
                        <div className="flex space-x-4">
                            <button onClick={secondaryButtonClicked} className="text-body bg-bodylight text-sm py-3 px-4 rounded-md w-24 sm:w-32">
                                Back
                            </button>
                            <button onClick={primaryButtonClicked} className="text-white bg-primary text-sm py-3 px-4 rounded-md w-24 sm:w-32">
                                {
                                    loading ? 'Loading...' : 'Submit'
                                }
                            </button>
                        </div>
                    </div>

                    <form action="#" className="">
                        <div className="px-6.5" style={{ maxHeight: "58vh", overflowY: 'auto' }}>
                            <div className="mb-4.5">
                                <input 
                                    type="text" 
                                    name="first_name"
                                    className={`w-full p-2 mt-6 mb-4 border border-bodylight1 shadow-sm rounded-lg ${validationErrors.first_name !== '' ? 'border-danger' : 'bg-transparent border-stroke'}`}
                                    placeholder="First name"
                                    value={user.first_name} 
                                    onChange={handleInputChange} 
                                />
                            </div>

                            <div className="mb-4.5">
                                <input 
                                    type="text" 
                                    name="last_name"
                                    className={`w-full p-2 mb-4 border border-bodylight1 shadow-sm rounded-lg ${validationErrors.last_name !== '' ? 'border-danger' : 'bg-transparent border-stroke'}`}
                                    placeholder="Last name"
                                    value={user.last_name} 
                                    onChange={handleInputChange} 
                                />
                            </div>

                            <div className="mb-4.5">
                                <input 
                                    type="text" 
                                    name="email"
                                    className={`w-full p-2 mb-4 border border-bodylight1 shadow-sm rounded-lg ${validationErrors.email !== '' ? 'border-danger' : 'bg-transparent border-stroke'}`}
                                    placeholder="Email"
                                    value={user.email} 
                                    onChange={handleInputChange} 
                                />
                            </div>

                            <div className="mb-4.5">
                                <input 
                                    type="password" 
                                    name="password"
                                    className={`w-full p-2 mb-4 border border-bodylight1 shadow-sm rounded-lg ${validationErrors.password !== '' ? 'border-danger' : 'bg-transparent border-stroke'}`}
                                    placeholder="Password"
                                    value={password} 
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                    }} 
                                />
                            </div>

                            <div className="mb-4.5">
                                <input 
                                    type="password" 
                                    name="password"
                                    className={`w-full p-2 mb-4 border border-bodylight1 shadow-sm rounded-lg ${validationErrors.confirmPassword !== '' ? 'border-danger' : 'bg-transparent border-stroke'}`}
                                    placeholder="Password"
                                    value={confirmPassword} 
                                    onChange={(e) => {
                                        setConfirmPassword(e.target.value);
                                    }} 
                                />
                            </div>

                            <div className="mb-4.5">
                                <input 
                                    type="text" 
                                    name="phone_number"
                                    className={`w-full p-2 mb-4 border border-bodylight1 shadow-sm rounded-lg ${validationErrors.phone_number !== '' ? 'border-danger' : 'bg-transparent border-stroke'}`}
                                    placeholder="Phone number"
                                    value={user.phone_number} 
                                    onChange={handleInputChange} 
                                />
                            </div>

                            <div className="mb-4.5">
                                <input 
                                    type="text" 
                                    name="organization_name"
                                    className={`w-full p-2 mb-4 border border-bodylight1 shadow-sm rounded-lg ${validationErrors.organization_name !== '' ? 'border-danger' : 'bg-transparent border-stroke'}`}
                                    placeholder="Organization name"
                                    value={user.organization_name} 
                                    onChange={handleInputChange} 
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Signup;