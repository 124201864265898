import { GET_DASHBOARD_METRICS, GET_SAVED_ARTICLES, GET_USER, LOADING_DASHBOARD_METRICS, LOADING_SAVED_ARTICLES, LOADING_USER, LOGOUT_USER } from "./UserActions";
import { User } from "../model/User";
import { DashboardMetrics } from "../model/DashboardMetrics";
import { SavedArticle } from "../model/SavedArticle";

export interface UserState {
    user: User | null;
    loadingUser: boolean;
    dashboardMetrics: DashboardMetrics;
    loadingDashboardMetrics: boolean;
    savedArticles: SavedArticle[];
    loadingSavedArticles: boolean;
    subscriptions: any;
    request_limit: number;
}

const initialState: UserState = {
    user: null,
    loadingUser: false,
    dashboardMetrics: {
        current_page: 0,
        total_pages: 0,
        request_count: 0,
        total_requests: 0,
        requests: []
    },
    loadingDashboardMetrics: false,
    savedArticles: [],
    loadingSavedArticles: false,
    subscriptions: {},
    request_limit: 0
};

const UserReducer = (state: UserState = initialState, action: any): UserState => {
    switch (action.type) {
        case GET_USER:
            return {
                ...state,
                user: action.payload.user,
                subscriptions: action.payload.subscriptions,
                request_limit: action.payload.request_limit,
                loadingUser: false
            };
        case LOADING_USER:
            return {
                ...state,
                loadingUser: true
            };
        case LOGOUT_USER:
            return {
                ...state,
                user: null
            };
        case GET_DASHBOARD_METRICS:
            return {
                ...state,
                dashboardMetrics: action.payload,
                loadingDashboardMetrics: false
            };
        case LOADING_DASHBOARD_METRICS:
            return {
                ...state,
                loadingDashboardMetrics: true
            };
        case GET_SAVED_ARTICLES:
            return {
                ...state,
                savedArticles: action.payload.articles,
                loadingSavedArticles: false
            };
        case LOADING_SAVED_ARTICLES:
            return {
                ...state,
                loadingSavedArticles: true
            };
        default:
            return state;
    }
};

export default UserReducer;