// store.ts
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { combineReducers } from 'redux';

import UserReducer from './users/UserReducer';
  
// Combine all reducers into a root reducer
const rootReducer = combineReducers({
    user: UserReducer
});
  
const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;

export type RootState = ReturnType<typeof store.getState>;
