import { useNavigate } from "react-router-dom";

import { getAuth, signOut } from "firebase/auth";

import { User } from "../../redux/model/User";
import { logoutUserAction } from "../../redux/users/UserActions";
import { RootState } from "../../redux/store";
import { connect } from "react-redux";
import { createBillingPortalSession } from "../../functions/userFunctions";

interface Props {
    user: User | null;
    subscriptions: any;
    setShow(setShow: boolean): void;
}

const SettingsModal:React.FC<Props> = ({ user, subscriptions, setShow }) => {

    const navigate = useNavigate();
    const auth = getAuth();

    function manageStripeSubscription() {
        const customer_id = user?.stripe_customer_id;
        if (user !== null && customer_id !== null && customer_id !== undefined) {
            createBillingPortalSession(customer_id);
        }
        // window.open('https://billing.stripe.com/p/login/5kA3eh49712X5vadQQ', '_blank');
    }

    return(
        <div className="fixed inset-0 bg-black bg-opacity-50 px-8 backdrop-filter flex justify-center items-center z-999999">
            <div className="bg-white dark:bg-black px-12 py-8 rounded-2xl shadow-lg relative w-full md:w-3/4 lg:w-1/2 xl:w-1/3">
                <div className='text-black dark:text-white text-title-lg font-semibold'>
                    <div className='flex justify-between items-start'>
                        <p className='pb-2'>
                            Settings
                        </p>

                        <button 
                            className='text-base'
                            onClick={() => {
                                setShow(false);
                            }}
                        >
                            X
                        </button>
                    </div>

                    <div className="h-0.5 w-full bg-bodylight1 mb-2" />
                    
                    <div className=" text-sm font-normal">
                        <p className="my-2">
                            {user?.first_name + ' ' + user?.last_name}
                        </p>
                        <p className="my-2">
                            {user?.email}
                        </p>
                        <p className="my-2">
                            {user?.organization_name !== null ? user?.organization_name : "No organization on file"}
                        </p>
                    </div>

                    <button
                        className="text-sm text-left underline rounded-md text-meta-5 mb-6"
                        onClick={() => {manageStripeSubscription()}}
                    >
                        Manage Subscription
                    </button>
                
                    <button
                        className='text-sm mt-2 w-full text-center text-white rounded-md p-2 bg-meta-1'
                        onClick={() => {
                            logoutUserAction();
                            window.postMessage({ type: "AUTH_STATE_CHANGED", user: null }, "*");
                            signOut(auth);
                            navigate('/');
                        }}
                    >
                        Logout
                    </button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: RootState) => {
    return {
        user: state.user.user,
        subscriptions: state.user.subscriptions
    }
}

const connectedComponent = connect(mapStateToProps)(SettingsModal);
export default connectedComponent;