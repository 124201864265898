import React, { useEffect, useState } from 'react';
import { User } from '../../redux/model/User';

import DefaultLayout from '../../components/Layout/DefaultLayout';
import Card from '../../components/Cards/Card';
import { DashboardMetrics } from '../../redux/model/DashboardMetrics';
import RequestTable from '../../components/Tables/RequestTable';
import PaywallModal from '../../components/Modals/PaywallModal';
import SavedArticlesTable from '../../components/Tables/SavedArticlesTable';
import { SavedArticle } from '../../redux/model/SavedArticle';
import ChatInterface from '../../components/Cards/ChatInterface';
import ActivateFreeTrialModal from '../../components/Modals/ActivateFreeTrialModal';
import TrialExpiredModal from '../../components/Modals/TrialExpiredModal';
import OnboardingModal from '../../components/Modals/OnboardingModal';
import HowToMakeRequestModal from '../../components/Modals/HowToMakeRequestModal';
import HowToSaveArticleModal from '../../components/Modals/HowToSaveArticleModal';

interface Props {
    user: User;
    subscriptions: any;
    request_limit: number;
    metrics: DashboardMetrics;
    loadingDashboardMetrics: boolean;
    savedArticles: SavedArticle[];
    loadingSavedArticles: boolean;
}

const Home: React.FC<Props> = ({ user, subscriptions,request_limit, metrics, savedArticles, loadingDashboardMetrics, loadingSavedArticles }) => {

    const [showFreeTrialModal, setShowFreeTrialModal] = useState(false);
    const [showTrialExpiredModal, setShowTrialExpiredModal] = useState(false);
    const [showOnboardingFlow, setShowOnboardingFlow] = useState(false);
    const [showHowToSaveArticleModal, setShowHowToSaveArticleModal] = useState(false);
    const [showHowToMakeRequestModal, setShowHowToMakeRequestModal] = useState(false);
    const [activeSubscriptions, setActiveSubscriptions] = useState<any[]>([]);
    const [loadingActiveSubscriptions, setLoadingActiveSubscriptions] = useState<boolean>(false);

    useEffect(() => {
        setLoadingActiveSubscriptions(true);
        let activeSubscriptions = [];
        if (subscriptions != null && subscriptions.data != null) {
            activeSubscriptions = subscriptions.data.filter((subscription: any) => {
                return subscription.status === 'active' || subscription.status === 'trialing';
            });
        }
        setActiveSubscriptions(activeSubscriptions);
        setLoadingActiveSubscriptions(false);

        if (activeSubscriptions.length === 0 && !('price_1ObmvyEqb54npZP0EZznfojO' in subscriptions) ) {
            // activate free trial modal – user has not yet activated their free trial
            // console.log('activate free trial modal');
            setShowFreeTrialModal(true);
        } else if (activeSubscriptions.length === 0 && 'price_1ObmvyEqb54npZP0EZznfojO' in subscriptions) {
            // free trial has expired – activate free trial expired modal
            // console.log('free trial expired modal');
            setShowTrialExpiredModal(true);
        } else if (activeSubscriptions.length > 0 && savedArticles.length === 0 && metrics.requests.length === 0) {
            // show onboarding flow
            console.log("show onboarding flow")
            setShowOnboardingFlow(true);
        }
    }, [subscriptions, savedArticles, metrics.requests]);
    
    return (
        <>
            <DefaultLayout>
                <div className='grid grid-cols-3 mt-8 space-x-8'>
                    <div className='col-span-2'>
                        <ChatInterface />
                        <SavedArticlesTable articles={savedArticles} showModal={() => setShowHowToSaveArticleModal(true)} />
                    </div>
                    <RequestTable requests={metrics.requests} showModal={() => setShowHowToMakeRequestModal(true)} />
                </div>
            </DefaultLayout>
            {
                showFreeTrialModal === true
                ?
                <ActivateFreeTrialModal user={user} />
                :
                null
            }
            {
                showTrialExpiredModal === true
                ?
                <TrialExpiredModal user={user} />
                :
                null
            }
            {
                loadingActiveSubscriptions === false && loadingActiveSubscriptions === false && loadingDashboardMetrics === false && activeSubscriptions.length > 0 && savedArticles.length === 0 && metrics.requests.length === 0 && showOnboardingFlow === true
                ?
                <OnboardingModal user={user} dismiss={() => setShowOnboardingFlow(false)} />
                :
                null
            }
            {
                showHowToMakeRequestModal === true
                ?
                <HowToMakeRequestModal user={user} dismiss={() => setShowHowToMakeRequestModal(false)} />
                :
                null
            }
            {
                showHowToSaveArticleModal === true
                ?
                <HowToSaveArticleModal user={user} dismiss={() => setShowHowToSaveArticleModal(false)} />
                :
                null
            }
        </>
    );
};

export default Home;
