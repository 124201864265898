import { useEffect, useState } from "react";

// import { AuthUser } from "../../redux/model/users/AuthUser";
// import { RootState } from "../../redux/store";
// import { connect } from "react-redux";

import { useNavigate } from "react-router-dom";

import { fetchSignInMethodsForEmail, getAuth, sendSignInLinkToEmail } from "firebase/auth";
import { User } from "../../redux/model/User";
import { RootState } from "../../redux/store";
import { connect } from "react-redux";

interface Props {
    user: User | null;
}

const Login: React.FC<Props> = ({ user }) => {

    const auth = getAuth();
    const navigate = useNavigate();

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [emailSent, setEmailSent] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        // if /login in url, check if user is logged in. if they are, navigate to home page
        if (user != null) {
            navigate('/');
        }
    }, [user, navigate]);

    function loginUserWithMagicLink() {
        setLoading(true);

        fetchSignInMethodsForEmail(auth, email).then((signInMethods) => {
            if (signInMethods.length > 0) {
                const currentDomain = window.location.hostname;
                const actionCodeSettings = {
                    // url: 'http://localhost:3000',
                    url: 'https://www.illiad-ai.com',
                    handleCodeInApp: true
                };

                sendSignInLinkToEmail(auth, email, actionCodeSettings)
                .then(() => {
                    window.localStorage.setItem('emailForSignIn', email);
                    setEmailSent(true);
                })
                .catch((error) => {
                    console.log(error);
                    setError("Error signing in. Please try again later.");
                });
            } else {
                console.log("No user found with that email");
                setError("No user found with that email");
            }
        }).catch((error) => {
            console.log(error);
            setError("No user found with that email");
        });
    }

    useEffect(() => {
        // if user is logged in, redirect to dashboard
        const loggedInUser = auth.currentUser;
        if (loggedInUser != null) {
            navigate('/');
        }
    }, [auth, navigate]);

    return (
        <div className="flex items-center justify-center min-h-screen">
            <div className="relative h-screen w-full bg-landingBackgroundGradient flex items-top justify-center" style={{ backgroundImage: 'linear-gradient(30deg, #5B1DF5, #ffaf7b)' }}>
                <div className="w-4/5 lg:w-2/5 h-1/4 p-8 bg-bodylight rounded-xl" style={{marginTop: "33vh"}}>
                    <h1 className="mb-4 text-2xl font-semibold text-black text-center">Sign in to Illiad AI</h1>

                    <div style={{height: "1px", width: "100%", backgroundColor: "#E5E5E5"}}></div>

                    <input 
                        type="text" 
                        className={`w-full p-2 mt-6 mb-4 border border-bodylight1 ${error !== '' ? 'border-danger' : 'bg-transparent border-stroke'} shadow-sm rounded-lg`}
                        placeholder="Email"
                        value={email} 
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }} 
                    />

                    {
                        error !== '' &&
                        <p className="text-danger -mt-2 mb-2 text-sm">{error}</p>
                    }

                    <button
                        className={`rounded-lg transition duration-300 w-full p-2 ${emailSent ? 'bg-meta-3' : 'bg-primary1'} text-white`}
                        onClick={() => {
                            if(!emailSent) {
                                loginUserWithMagicLink();
                            }
                        }}
                    >
                        {emailSent ? <><span className="mr-2">✓</span> Login Link Sent</> : loading ? "Sending Login Link..." : "Send Login Link"}
                    </button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: RootState) => {
    return {
        user: state.user.user
    }
}

const connectedComponent = connect(mapStateToProps, null)(Login);
export default connectedComponent;
