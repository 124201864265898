import React, { useState } from "react";
import { SavedArticle } from "../../redux/model/SavedArticle";

interface Props {
    article: SavedArticle;
}

const SavedArticleCard: React.FC<Props> = ({ article }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    function formatRequestTitle(title: string) {
        if (title.length > 200) {
            return title.slice(0, 200) + "...";
        }
        return title;
    }

    function castArticleContentToJSON(articleSummary: string) {
        try {
            return JSON.parse(articleSummary);
        } catch (error) {
            return {
                summary: "Article summary is currently loading. Please check back in a few minutes.",
                takeaways: []
            };
        }
    }
    const articleContent = castArticleContentToJSON(article.summary);

    // function to format url title to only show domain
    function formatUrl(url: string) {
        let formattedUrl = url.replace(/(^\w+:|^)\/\//, '');
        if (formattedUrl.length > 30) {
            return formattedUrl.slice(0, 30) + "...";
        }
        return formattedUrl;
    }

    function openInNewTab(url: string) {
        const win = window.open(url, '_blank');
        win?.focus();
    }

    return (
        <div className={`col-span-1 w-full rounded-xl shadow-sm bg-white dark:bg-boxdark border dark:border-boxdark border-bodylight1 overflow-hidden relative ${isExpanded ? 'min-h-auto' : 'max-h-80'}`}>
            <div className="px-4 py-3">
                <p className="text-black dark:text-white text-title-sm font-semibold">
                    {formatRequestTitle(article.title)}
                </p>
                <button 
                    className="underline text-meta-5 text-xs"
                    onClick={() => openInNewTab(article.url)}
                >
                    {formatUrl(article.url)}
                </button>
                <p className="text-body font-light dark:text-bodylight1 text-sm mt-1">
                    {articleContent.summary}
                </p>
                {
                    articleContent.takeaways.length > 0 && (
                        <p className="text-title-xsm font-semibold mt-2 text-black dark:text-white">
                            Key Takeaways
                        </p>
                    )
                }
                <ul className={`list-disc pl-5 mt-2 text-sm text-black dark:text-bodylight1 ${isExpanded ? '' : 'max-h-24 overflow-hidden'}`}>
                    {
                        // check that takeaways exist and is a list before mapping
                        articleContent.takeaways && Array.isArray(articleContent.takeaways) 
                        ?
                        articleContent.takeaways.map((takeaway: string, index: number) => (
                        <li key={index}>{takeaway}</li>
                        ))
                        :
                        articleContent.takeaways && typeof articleContent.takeaways === 'object'
                        ?
                        Object.keys(articleContent.takeaways).map((key: string, index: number) => (
                            <li key={index}>{articleContent.takeaways[key]}</li>
                        ))
                        :
                        null
                    }
                </ul>
            </div>
            {
                !isExpanded && (
                    <div>
                        <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-white dark:from-black to-transparent h-40 content-end" />
                        <button onClick={() => setIsExpanded(true)} className="absolute bottom-4 w-full text-black dark:text-white font-bold text-title-xsm">
                            Read More
                        </button>
                    </div>
                )
            }
            {
                isExpanded && (
                    <div>
                        <button onClick={() => setIsExpanded(false)} className="mb-4 w-full text-black dark:text-white font-bold text-title-xsm">
                            Read Less
                        </button>
                    </div>
                )
            }
        </div>
    );
}

export default SavedArticleCard;
