import { useNavigate } from "react-router-dom";

import { getAuth } from "@firebase/auth";

import { User } from "../../redux/model/User";
import { useRef, useState } from "react";

import enableGif from '../../img/enable.gif';
import enable from '../../img/enable.png';
import exampleChat from '../../img/example-chat.gif';
import popup from '../../img/popup.gif';

interface Props {
    user: User;
    dismiss: () => void;
}

const HowToSaveArticleModal:React.FC<Props> = ({ user, dismiss }) => {

    const navigate = useNavigate();
    const auth = getAuth();
    const ref = useRef(null);

    const [currentPage, setCurrentPage] = useState<number>(1);

    function openLink(url: string) {
        window.location.href = url;
    }

    function openLinkNewTab(url: string) {
        window.open(url, '_blank');
    }

    return(
        <div className="fixed inset-0 bg-black bg-opacity-5 px-8 backdrop-filter backdrop-blur-sm flex justify-center items-center z-9999">
            <div className="bg-white dark:bg-black px-12 py-8 rounded-2xl shadow-lg relative w-full md:w-3/4 lg:w-1/2 xl:w-1/3">
                <div className='text-black dark:text-white text-title-lg font-semibold'>
                    {
                        currentPage === 1
                        ?
                        <div>
                            <div className='flex justify-between items-start'>
                                <p className='pb-2'>
                                    How to Save an Article
                                </p>

                                <button 
                                    className='text-base underline'
                                    onClick={() => {
                                        dismiss();
                                    }}
                                >
                                    Dismiss
                                </button>
                            </div>

                            <p className="text-sm font-normal mt-4">
                                Follow this quick onboarding process to finish setting up your account.
                            </p>
                            <p className="text-sm font-normal mt-4">
                                To begin, head to <button className="underline text-black dark:text-white" onClick={() => openLink('https://chromewebstore.google.com/detail/illiad/jiggadnmcmbdpofgkablihabdghjdjhm')}>this link</button> to download the Illiad Chrome Extension. When you're done, the back button to come back to this page and click the button below.
                            </p>
                            <p className="text-sm font-normal mt-4">
                                You should see a lightning bolt to the right of this modal. If you don't see it, try  <button className="underline text-black dark:text-white" onClick={() => window.location.reload()}>refreshing the page</button>
                            </p>
                            
                            <div className="w-full">
                                <img 
                                    src={enable} 
                                    alt="" 
                                    className=" w-1/6 rounded-full mt-6 border border-bodylight dark:border-body shadow-md" style={{marginLeft: "42%"}} />
                            </div>
                        
                            <button 
                                className='bg-custom-gradient text-white font-bold text-title-sm py-2 px-4 rounded-lg mt-10 w-full shadow-md'
                                onClick={() => {
                                    setCurrentPage(2);
                                }}
                            >
                                I see the lightning bolt – let's go!
                            </button>
                        </div>
                        :
                        currentPage === 2
                        ?
                        <div>
                            <div className='flex justify-between items-start'>
                                <p className='pb-2'>
                                    Save a Page
                                </p>

                                <button 
                                    className='text-base underline'
                                    onClick={() => {
                                        setCurrentPage(1);
                                    }}
                                >
                                    Go back
                                </button>
                            </div>

                            <p className="text-sm font-normal mt-4">
                                Lastly, you can save any page that you visit. 
                            </p>
                            <p className="text-sm font-normal mt-4">
                                Just like the last step, you can mouse over the widget's icon, and you'll see it expand after about 1 second. This time, click the save button in the middle.
                            </p>
                            <div className="w-full">
                                <img 
                                    src={popup} 
                                    alt="" 
                                    className=" w-3/5 mt-8 shadow-md border border-bodylight1 rounded-lg" style={{marginLeft: "20%"}} />
                            </div>
                            <p className="text-sm font-normal mt-8">
                                You can get started now using Illiad on any webpage! We recommend saving <button className="underline text-black dark:text-white" onClick={() => openLinkNewTab('https://medium.com/@max-gillespie/how-i-never-forget-anything-️-b078c8c50b8a')}>this page</button> to your knowledgebase to get started, so you never forget how to use the tool!
                            </p>
                            
                            <button 
                                className='bg-custom-gradient text-white font-bold text-title-sm py-2 px-4 rounded-lg mt-10 w-full shadow-md'
                                onClick={() => {
                                    dismiss();
                                }}
                            >        
                                Got it – let's get started!
                            </button>
                        </div>
                        :
                        null
                    }
                </div>
            </div>
        </div>
    );
}

export default HowToSaveArticleModal;