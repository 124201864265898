import React from 'react';
import { useNavigate } from 'react-router-dom';

import get_context from '../img/landing/v2/get-context.png';
import webpage_chat from '../img/landing/v2/webpage-chat.png';
import portal from '../img/landing/v2/landing-light.png';

const Landing: React.FC = () => {

    const navigate = useNavigate(); 

    const openInNewTab = (url: string) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    return (
        <div className="min-h-screen flex flex-col">
            <div className="relative h-screen bg-landingBackgroundGradient flex items-top justify-center" style={{ backgroundImage: 'linear-gradient(30deg, #5B1DF5, #ffaf7b)' }}>

                <div className='absolute top-4 right-20 flex justify-end'>
                    <div className="mr-6">
                        <button className='text-white text-md mt-2.5' onClick={() => navigate('/login')}>
                            Login
                        </button>
                    </div>
                    <button onClick={() => openInNewTab("https://chromewebstore.google.com/detail/illiad-ai-personal-assist/jiggadnmcmbdpofgkablihabdghjdjhm")}>
                        <p className='text-primary text-md bg-white p-2 rounded-full px-4 mt-1'>
                            Add to Chrome
                        </p>
                    </button>
                </div>

                <div className='text-center mt-52 text-bodylight1'>
                    <div className='grid grid-cols-1 md:grid-cols-2 text-left'>
                        <div className='col-span-1 ml-20 mt-0 md:mt-4'>
                            <h1 className="text-4xl w-2/3 md:text-5xl lg:text-6xl font-semibold mb-4">Transform a Page of Text into a Library of Knowledge 📚</h1>

                            <p className="text-md md:text-lg w-5/6 font-extralight mt-10 mb-10">
                                Highlight unfamiliar terms, chat with any document/webpage, and save relevant page into a personalized, AI-powered knowledgebase.
                            </p>

                            <div className="flex justify-start text-primary1 font-normal">
                                <button className="bg-bodylight1 rounded-lg py-3 w-5/6 md:w-2/3 transition-colors" onClick={() => navigate('/signup')}>
                                    Sign up today to start your 14 day free trial
                                </button>
                            </div>
                            <p className='w-5/6 md:w-2/3 text-center mt-2'>
                                No credit card required. $9.99/mo after trial ends.
                            </p>

                            <div className="flex justify-center w-5/6 md:w-2/3">
                                <a href="https://saasaitools.com/?ref=badge" target="_blank">
                                    <img src="https://saasaitools.com/storage/2023/12/badge-light.webp" alt="SaaS AI Tools" width="180" style={{marginTop: "40px"}} />
                                </a>
                            </div>
                        </div>


                        <div className='col-span-1'>
                            <video className="w-4/5 ml-13 mt-10 md:mt-0 rounded-2xl shadow-lg h-auto" loop autoPlay muted>
                                <source src="/landing/ask-ai-webpage.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </div>

            <div className="relative bg-white flex items-center justify-center">
                <div>
                    <div className='grid grid-cols-1 md:grid-cols-2 text-center mb-28'>
                        <div className='col-span-1 w-3/4' style={{marginLeft: "12.5%"}}>
                            <h1 className="text-3xl text-black font-semibold mb-4 mt-10 lg:mt-60">
                                Instant Context for any Page
                            </h1>
                            <p className="text-md text-black font-normal mb-6">
                                Dive deeper on any content you’re reading in Chrome with a simple highlight.
                            </p>
                        </div>

                        <div className='col-span-1 w-5/6 md:w-3/5 mt-10 lg:mt-16 ml-12 md:ml-40'>
                            <img src={get_context} alt="Ask Button" className="mx-auto rounded-2xl shadow-lg border border-bodylight1" />
                        </div>
                    </div>

                    <div className='grid grid-cols-1 md:grid-cols-2 text-center lg:mt-20'>
                        <div className='col-span-1 w-5/6 md:w-3/4 mt-10 lg:mt-0 ml-12 md:ml-60'>
                            <img src={webpage_chat} alt="Ask Button" className="mx-auto rounded-2xl shadow-lg border border-bodylight1" />
                        </div>
                        <div>
                            <div className='col-span-1 w-3/4' style={{marginLeft: "12.5%"}}>
                                <div className="mt-10 md:mt-65">
                                    <h1 className="text-3xl text-black font-semibold mb-4">
                                        Chat with Content in Realtime
                                    </h1>
                                    <p className="text-md text-black font-normal mb-6">
                                        Ask detailed questions about what you’re reading as if you’re talking to the author.
                                    </p>
                                </div>
                                <div className="flex justify-start text-white font-normal mt-10">
                                    <button className="bg-primary1 rounded-lg py-3 w-3/4 transition-colors" style={{marginLeft: "12.5%"}} onClick={() => navigate('/signup')}>
                                        Create your account today and start your 14 day free trial
                                    </button>
                                </div>
                                <p className='w-full text-center mt-2'>
                                    No credit card required. $9.99/mo after trial ends.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='grid grid-cols-1 md:grid-cols-2 text-center mt-20 md:-mt-32'>
                        <div className='col-span-1 w-3/4 md:mt-20' style={{marginLeft: "12.5%"}}>
                            <h1 className="text-3xl text-black font-semibold mb-4 lg:mt-60">
                                Seamlessly Save any Content as you're Reading
                            </h1>
                            <p className="text-md text-black font-normal mb-6">
                                By simply clicking the save button in the Illiad widget, you'll be able to save any content you're reading to your personal knowledgebase.
                            </p>
                        </div>

                        <div className='col-span-1'>
                            <video className="w-2/5 ml-40 md:ml-60 mt-4 md:mt-50 rounded-2xl h-auto" loop autoPlay muted>
                                <source src="/landing/popup-demo.mov" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>

                    <div className='grid grid-cols-1 md:grid-cols-2 text-center mt-20 mb-60'>
                        <div className='col-span-1 w-5/6 mt-0 md:mt-0' style={{marginLeft: "12.5%"}}>
                            <img src={portal} alt="History" className="mx-auto rounded-md w-full shadow-lg" />
                        </div>

                        <div className='col-span-1 w-3/4' style={{marginLeft: "12.5%"}}>
                            <h1 className="text-3xl text-black font-semibold mb-4 mt-20 md:mt-45">
                                Chat with a Knowledgebase of Saved Pages
                            </h1>
                            <p className="text-md text-black font-normal mb-6">
                                As soon as you save something, it becomes a part of your knowledgebase. From there, you can ask questions to a chatbot that grows with you.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="relative bg-landingBackgroundGradient flex flex-col justify-between" style={{ backgroundImage: 'linear-gradient(30deg, #5B1DF5, #ffaf7b)', height: "75vh"}}>
                <div className='text-center mt-52 text-bodylight1'>
                    <h1 className="text-4xl w-2/3 md:text-5xl lg:text-7xl font-semibold mb-4" style={{marginLeft: "17%"}}>Elevate Your Professional and Academic Pursuits with Illiad Today 📈</h1>

                    <div className="flex justify-center text-primary1 font-normal lg:mt-12">
                        <button className="bg-bodylight1 text-indigo-600 rounded-lg px-6 py-3 hover:bg-gray-200 transition-colors" onClick={() => navigate('/signup')}>
                            Sign up today to start your 14 day free trial
                        </button>
                    </div>
                    <p className='w-full text-center mt-2'>
                        No credit card required. $9.99/mo after trial ends.
                    </p>
                </div>

                <footer className="w-full text-left ml-4 mb-4 text-white text-xs">
                    <p>© 2024 Sweetwater Labs. All rights reserved.</p>
                </footer>
            </div>

        </div>
    );
}

export default Landing;
