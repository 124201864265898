import { useNavigate } from "react-router-dom";

import { signOut, getAuth } from "@firebase/auth";

import { User } from "../../redux/model/User";
import { logoutUserAction } from "../../redux/users/UserActions";
import { createCheckoutSession, createFreeTrial } from "../../functions/userFunctions";


interface Props {
    user: User;
}

const ActivateFreeTrialModal:React.FC<Props> = ({ user }) => {

    const navigate = useNavigate();
    const auth = getAuth();

    return(
        <div className="fixed inset-0 bg-black bg-opacity-50 px-8 backdrop-filter backdrop-blur-sm flex justify-center items-center z-999999">
            <div className="bg-white dark:bg-black px-12 py-8 rounded-2xl shadow-lg relative w-full md:w-3/4 lg:w-1/2 xl:w-1/3">
                <div className='text-black dark:text-white text-title-lg font-semibold'>
                    <div className='flex justify-between items-start'>
                        <p className='pb-2'>
                            Activate Your 14 Day Free Trial
                        </p>

                        <button 
                            className='text-base underline'
                            onClick={() => {
                                logoutUserAction();
                                window.postMessage({ type: "AUTH_STATE_CHANGED", user: null }, "*");
                                signOut(auth);
                                navigate('/');
                            }}
                        >
                            Logout
                        </button>
                    </div>
                    
                    <ul className='text-sm font-light pb-2 list-disc list-inside'>
                        <li>
                            No credit card required
                        </li>
                        <li>
                            Priority email support
                        </li>
                        <li>
                            Get immediate answers on any webpage
                        </li>
                        <li>
                            Build your own knowledge base
                        </li>
                        <li>
                            Seamless refunds
                        </li>
                    </ul>
                
                    <button 
                        className='bg-custom-gradient text-white font-bold text-title-sm py-2 px-4 rounded-lg mt-2 w-full shadow-md'
                        onClick={() => {
                            createCheckoutSession('price_1ObmvyEqb54npZP0EZznfojO', user.stripe_customer_id);
                        }}
                    >
                        Enter payment details and activate free trial!
                    </button>
                    <button
                        className='underline text-sm mt-2 w-full text-center'
                        onClick={() => {
                            createFreeTrial(user.stripe_customer_id).then((response) => {
                                window.location.reload();
                            });
                        }}
                    >
                        Activate subscription and enter payment details later
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ActivateFreeTrialModal;