import { getDashboardMetrics, getUser, getSavedArticles } from "../../functions/userFunctions";

export const GET_USER = 'GET_USER';
export const LOADING_USER = 'LOADING_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const GET_DASHBOARD_METRICS = 'GET_DASHBOARD_METRICS';
export const LOADING_DASHBOARD_METRICS = 'LOADING_DASHBOARD_METRICS';
export const GET_SAVED_ARTICLES = 'GET_SAVED_ARTICLES';
export const LOADING_SAVED_ARTICLES = 'LOADING_SAVED_ARTICLES';

export const getUserAction = (userId: string) => {
    return async (dispatch: any) => {
        dispatch({
            type: LOADING_USER
        })
        const user = await getUser(userId);
        dispatch({
            type: GET_USER,
            payload: user
        });
    }
}

export const logoutUserAction = () => {
    return async (dispatch: any) => {
        dispatch({
            type: LOGOUT_USER
        });
    }
}

export const getDashboardMetricsAction = (userId: string, pageNumber: number) => {
    return async (dispatch: any) => {
        dispatch({
            type: LOADING_DASHBOARD_METRICS
        });
        const metrics = await getDashboardMetrics(userId, pageNumber);
        dispatch({
            type: GET_DASHBOARD_METRICS,
            payload: metrics
        });
    }
}

export const getSavedArticlesAction = (userId: string) => {
    return async (dispatch: any) => {
        dispatch({
            type: LOADING_SAVED_ARTICLES
        });
        const articles = await getSavedArticles(userId);
        dispatch({
            type: GET_SAVED_ARTICLES,
            payload: articles
        });
    }
}